
// export const CAPTCH_KEY = "6Lf9YjIUAAAAAExqTaLdZRClM5mAa5g-kthnuZ1V"
// export const ENCRYPT_KEY ="ABCDEFGHIGK12345"
// export const APIURL = "http://localhost/"
// export const LOGINURL = "http://localhost/"
// export const HOSTTYPE =window.location.protocol+"//"
// export const SITEURL = window.location.host
// export const ISFOLDER = false;
// export const FOLDERNAME ="/opl";
export const CAPTCH_KEY = "6Lf9YjIUAAAAAExqTaLdZRClM5mAa5g-kthnuZ1V"
export const ENCRYPT_KEY ="ABCDEFGHIGK12345"
export const APIURL = "http://localhost/"
export const LOGINURL = "http://localhost/"
export const HOSTTYPE =window.location.protocol+"//"
export const SITEURL = window.location.host
export const ISFOLDER = true
export const FOLDERNAME ="/opl";
export const ISPRODUCTION = true;
// export let NEWAPIURL =  "http://apilocalhost";
export let NEWAPIURL = getCookie("apiBasedCustomUrl") ? getCookie("apiBasedCustomUrl") : "https://api.onepaperlane.net";


function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = document.cookie;
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
