import React, { Component } from 'react';
import { HashRouter, Route, Switch,BrowserRouter } from 'react-router-dom';
import './App.scss';
import { ISFOLDER,FOLDERNAME ,ENCRYPT_KEY,APIURL,HOSTTYPE,SITEURL} from "./views/Constant";
import axios from "axios";


var encryptor = require("simple-encryptor")(ENCRYPT_KEY);
var parts = window.location.hostname.split('.');
var sndleveldomain = parts.slice(-2).join('.');
var NEWAPIURL = HOSTTYPE+SITEURL+"/";



const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));


const Inviteduser = React.lazy(() => import('./views/Login/invitedusers_otp'));
const Forgotpassword = React.lazy(() => import('./views/Login/Forgotpassword'));
const Verifycode = React.lazy(() => import('./views/Login/Verifycode'));
const Verifycodemobile = React.lazy(() => import('./views/Login/Verifycodemobile'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const LandingPage = React.lazy(() => import('./views/Login'));







class App extends Component {

   getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return "";
}
  

 UNSAFE_componentWillMount() {
    if(this.getCookie('googtrans') !== undefined && this.getCookie('googtrans') != '' &&  this.getCookie('googtrans')  !== null ){
            localStorage.setItem(
              "language_transexist",
              "yes"
            );
   }
   /* if(localStorage.getItem("language_transexist") !== null ){
        var addScript = document.createElement('script');
        addScript.setAttribute(
          'src',
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(addScript);
    } */

    var formdata = new FormData();
      if( localStorage.getItem('cookieobj')  !== null){
            var response = JSON.parse(localStorage.getItem('cookieobj'));
            if(response["access_token"] !== undefined){
            localStorage.setItem("sessiontoken", encryptor.encrypt(response["access_token"]));
              this.getuserinfo();
            }
            if(response["access_url"] !== undefined){
            localStorage.setItem(
              "JS",
              encryptor.encrypt(response['access_url'])
            );
         }
         if(response["permissionsarr"] !== undefined){
          localStorage.setItem(
              "permissons",
              encryptor.encrypt(JSON.parse(response["permissionsarr"]))
            ); 
         }
         if(response["role_access"] !== undefined){
             var rolebase =btoa("role_access");
            localStorage.setItem(
             rolebase,
              encryptor.encrypt(response["role_access"])
            );
         }
          if(response["message_access"] !== undefined){
            localStorage.setItem(
              "message_access",
              encryptor.encrypt(response["message_access"])
            );
         }
          if(response["onevault_access"] !== undefined){
           localStorage.setItem(
              "onevault_access",
              encryptor.encrypt(response["onevault_access"])
            );;
         }
          if(response["csrfToken"] !== undefined){
            localStorage.setItem(
              "csrfToken",
              encryptor.encrypt(response["csrfToken"])
            );;
         }
         if(response["access_for"] !== undefined){
            localStorage.setItem(
              "dashboard_sub_type",
              encryptor.encrypt(response["access_for"])
            );;
         }
       }

      if( localStorage.getItem('sessiontoken')  === null){
    var obj=[];
    obj.push("access_token");
     obj.push("access_for");
    obj.push("access_url");
    obj.push("permissionsarr");
    obj.push("role_access");
    obj.push("access_token");
    obj.push("message_access");
    obj.push("onevault_access");
    obj.push("csrfToken");
   
    var objassihn = Object.assign({}, obj);
    var jsondata =JSON.stringify(objassihn);
     formdata.append("cookieData",jsondata);
     axios.post(NEWAPIURL + `oplv4/readCookieAjax`, formdata).then(res => {
      if(res.data.success  == true){
         var response = res.data.data;
         var cookieobj = {};
         if(response["access_token"] !== undefined){
            localStorage.setItem("sessiontoken", encryptor.encrypt(response["access_token"]));
            cookieobj["access_token"] = response.access_token;
            this.getuserinfo();
         }
         if(response["access_url"] !== undefined){
            localStorage.setItem(
              "JS",
              encryptor.encrypt(response['access_url'])
            );
             cookieobj["access_url"] = response.access_url;
         }
         if(response["permissionsarr"] !== undefined){
          /* localStorage.setItem(
              "permissons",
              encryptor.encrypt(response['permissionsarr'])
            ); */
            cookieobj["permissionsarr"] = response.permissionsarr;
         }
         if(response["role_access"] !== undefined){
             var rolebase =btoa("role_access");
            localStorage.setItem(
             rolebase,
              encryptor.encrypt(response["role_access"])
            );
         }
        
         if(response["message_access"] !== undefined){
            localStorage.setItem(
              "message_access",
              encryptor.encrypt(response["message_access"])
            );
         }
         if(response["onevault_access"] !== undefined){
           localStorage.setItem(
              "onevault_access",
              encryptor.encrypt(response["onevault_access"])
            );;
         }
         if(response["csrfToken"] !== undefined){
            localStorage.setItem(
              "csrfToken",
              encryptor.encrypt(response["csrfToken"])
            );;
             cookieobj["csrfToken"] = response.csrfToken;
         }
         if(response["access_for"] !== undefined){
            localStorage.setItem(
              "dashboard_sub_type",
              encryptor.encrypt(response["access_for"])
            );;
             cookieobj["access_for"] = response.access_for;
         }
         localStorage.setItem(
              "cookieobj",
              JSON.stringify(cookieobj)
            );
    
      }
    

  });
 }
    

     var sessiontoken = encryptor.decrypt(localStorage.getItem('sessiontoken'));  
      if( localStorage.getItem('sessiontoken')  !== null){  
    var token = 'Bearer '+sessiontoken;
    axios.post(NEWAPIURL + `v4/users/userrbaccheck?type=RESTAPI`, formdata,{headers: {
    'Authorization': token,
    'access-type':'RESTAPI'

  }}).then(res => {
    if( res.data !== undefined && res.data.data !== undefined && res.data.data.data !== undefined){
      var permissons = res.data.data.data;
      if(res.data.data !== null && res.data.data.controlcenterenable !== undefined){
         var controlcenterenable = res.data.data.controlcenterenable;
         localStorage.setItem("controlcenterenable", encryptor.encrypt(controlcenterenable));

      }
      if(res.data.data !== null && res.data.data.onevaultenable !== undefined){
         var onevaultenable = res.data.data.onevaultenable;
         localStorage.setItem("onevaultenable", encryptor.encrypt(onevaultenable));

      }
     
      //alert(res.data.data.controlcenterenable);
  //    localStorage.setItem("permissonsdaa", encryptor.encrypt(permissons));

//     localStorage.setItem("permissons", encryptor.encrypt(permissons));

    }
    

  });
}
  
  }
  getuserinfo(){
    if(localStorage.getItem('sessiontoken') !== undefined && localStorage.getItem('sessiontoken') != '' && localStorage.getItem('sessiontoken')  !== null ){
      /* localStorage.setItem(
              "sessiontoken",
              encryptor.encrypt(this.getCookie('access_token'))
            );*/
         var formdata = new FormData();
      formdata.append("id","1");
     
     var sessiontoken = encryptor.decrypt(localStorage.getItem('sessiontoken')); 
    var token = 'Bearer '+sessiontoken;
    axios.post(NEWAPIURL + `OrpApi/getLoggedUserInfo?type=RESTAPI`, formdata,{headers: {
    'Authorization': token,
    'access-type':'RESTAPI'

  }}).then(res => {
        if(res.data.status == 200 ){
            try{
               if(res.data.data.company_logo != '' && res.data.data.company_logo !== null){
                    localStorage.setItem("company_logo", encryptor.encrypt(res.data.data.company_logo));
                }
                if(res.data.data.user_image != '' && res.data.data.user_image !== null){
                   localStorage.setItem("user_image", encryptor.encrypt(res.data.data.user_image));
                }
                if(res.data.data.role){
                  var rolebase =btoa("role_access");
                  localStorage.setItem(rolebase, encryptor.encrypt(res.data.data.role));
                }
                if(res.data.data.dashboard_sub_type !== undefined){
                   localStorage.setItem("dashboard_sub_type", encryptor.encrypt(res.data.data.dashboard_sub_type ));
                }
                if(res.data.data.dashboard){
                  var dashboardbase =btoa("dashboard");
                  localStorage.setItem(dashboardbase, encryptor.encrypt(res.data.data.dashboard));
                }
                if(res.data.data.dashboard_sub_type){
                  var dashsubbase =btoa("dashboard_sub_type");
                  localStorage.setItem(dashsubbase, encryptor.encrypt(res.data.data.dashboard_sub_type));
                }
                if(res.data.data.linked_user !== undefined && res.data.data.linked_user.data !== undefined){
                   var linkeduser = res.data.data.linked_user.totalCount;
                   if(linkeduser > 0){
                     localStorage.setItem("linkeduser", encryptor.encrypt(linkeduser));

                   }else{
                           localStorage.removeItem("linkeduser");

                   }
                 
                }
               
        }catch (error){
            
        }
       }
    })

      
    }
   
  }
   




  render() {
      if(this.getCookie('permissionsarr') !== undefined && this.getCookie('permissionsarr') != '' &&  this.getCookie('permissionsarr')  !== null ){
       var permissionsarr = this.getCookie('permissionsarr');
       var newpermissionsarr = JSON.parse(permissionsarr);
      localStorage.setItem("permissons", encryptor.encrypt(newpermissionsarr));
      document.cookie = "permissionsarr=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain="+sndleveldomain;



     }
     if(this.getCookie('access_url') !== undefined && this.getCookie('access_url') != '' &&  this.getCookie('access_url')  !== null ){
       localStorage.setItem(
              "JS",
              encryptor.encrypt(this.getCookie('access_url'))
            );


    }
    if(this.getCookie('role_access') !== undefined && this.getCookie('role_access') != '' &&  this.getCookie('role_access')  !== null ){
       var rolebase =btoa("role_access");
             
       localStorage.setItem(
             rolebase,
              encryptor.encrypt(this.getCookie('role_access'))
            );


    }
    if(this.getCookie('csrfToken') !== undefined && this.getCookie('csrfToken') != '' &&  this.getCookie('csrfToken')  !== null ){
     
       localStorage.setItem(
              "csrfToken",
              encryptor.encrypt(this.getCookie('csrfToken'))
            );


    } 
     if(this.getCookie('testaccess_url') !== undefined && this.getCookie('testaccess_url') != '' &&  this.getCookie('testaccess_url')  !== null ){


    } 
     if(this.getCookie('access_for') !== undefined && this.getCookie('access_for') != '' &&  this.getCookie('access_url')  !== null ){
       localStorage.setItem(
              "dashboard_sub_type",
              encryptor.encrypt(this.getCookie('access_for'))
            );
             document.cookie = "access_for=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain="+sndleveldomain;


    }

    if(this.getCookie('access_url') !== undefined && this.getCookie('access_url') != ''  &&  this.getCookie('access_url')  !== null ){
       localStorage.setItem(
              "sessiontoken",
              encryptor.encrypt(this.getCookie('access_token'))
            );
}
if(this.getCookie('access_token') !== undefined && this.getCookie('access_token') != ''  &&  this.getCookie('access_token')  !== null ){
       localStorage.setItem(
              "sessiontoken",
              encryptor.encrypt(this.getCookie('access_token'))
            );
}
if(this.getCookie('access_url') !== undefined && this.getCookie('access_url') != ''  &&  this.getCookie('access_url')  !== null ){
       localStorage.setItem(
              "message_access",
              encryptor.encrypt(this.getCookie('message_access'))
            );
}
if(this.getCookie('access_url') !== undefined && this.getCookie('access_url') != ''  &&  this.getCookie('access_url')  !== null ){
       localStorage.setItem(
              "onevault_access",
              encryptor.encrypt(this.getCookie('onevault_access'))
            );
}



 if(this.getCookie('access_url') !== undefined && this.getCookie('access_url') != '' &&  this.getCookie('access_url')  !== null ){
      
             document.cookie = "access_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain="+sndleveldomain;
              document.cookie = "message_access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain="+sndleveldomain;
               document.cookie = "onevault_access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain="+sndleveldomain;


    }  


      if(ISFOLDER === true){
        var foldername =  FOLDERNAME;
        var homelayout =FOLDERNAME+'/';
      }else{
        var foldername =  ""; 
        var homelayout ='/';
      }
    return (

     <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>

            <Route  path={foldername+"/invitedusers_otp"} name="Invited user otp Page" render={props => <Inviteduser {...props}/>} />
              <Route  path={foldername+'/setpassword'} name="Verify Code" render={props => <Verifycodemobile {...props}/>} />
            <Route  path={foldername+'/verifycode'} name="Verify Code" render={props => <Verifycode {...props}/>} />
            <Route  path={foldername+'/forgotpassword'} name="Forgot Password" render={props => <Forgotpassword {...props}/>} />
            <Route  path={foldername+'/login'} name="Login Page" render={props => <LandingPage {...props}/>} />

            <Route path={homelayout} name="Home" render={props => <DefaultLayout {...props}/>} />
          </Switch>
        </React.Suspense>
       </BrowserRouter>
    );
  }
}

export default App;
