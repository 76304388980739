const initialState = {};
console.log();
const reducer = (state = initialState,parametername)=>{
	const newState = {...state};
	const parameters = parametername.type;
	const value = parametername.value;
	newState[parameters] = value;
	return newState;

}
export default reducer;